import { useContext, React, useEffect, useState } from 'react'
import { AuthContext } from '../../../Context/authContext';
import { PinContext } from '../../../Context/pinContext';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { updatePin, updateCustomPin, IDMapping } from '../../../API/user';
import TextField from '@mui/material/TextField';

export default function Dashboard() {
  const authContext = useContext(AuthContext);
  const pinContext = useContext(PinContext);
  const { auth } = authContext;
  const { customPin, setCustomPin, saveCustomPin, resetCustomPin, ip, setIP, saveIP } = pinContext;
  const navigate = useNavigate();
  const [currCustomPin, setCurrCustomPin] = useState("");
  const [currIP, setCurrIP] = useState("");

  const updateIp = () => {
    setIP(currIP);
  }
  
  useEffect(() => {
    setCurrIP(ip);
  }, [ip]);

  useEffect(() => {
    if (!auth) {
      navigate('/');
    }
  }, [auth, navigate]);
  return (
    <>
      <Container sx={{ py: 4 }} maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>

          {Object.keys(IDMapping).map((key) => {
            return (
              <>
                <h3>{key}</h3>
                <Stack spacing={2} direction="row">
                  {Object.keys(IDMapping[key]).map((key2) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <Button variant="contained" onClick={() => {
                        updatePin(key, key2, ip).then((data) => {
                          console.log(data);
                        })
                      }}>{key2}</Button>
                    )
                  })}
                </Stack>
                <br />
              </>
            )
          })}

        </Box>

        <br />
        <br />

        {/* Custom Pins */}
        <Box sx={{ flexGrow: 1 }}>
          <p>Custom Pins</p>

          {/* Input field for custom pins */}
          <Stack spacing={2} direction="row">
            <TextField id="outlined-basic" label="Custom Pins" variant="outlined"
              value={currCustomPin}
              onChange={(e) => {
                setCurrCustomPin(e.target.value);
              }}
            />
            <Button variant="contained" onClick={() => {
              updateCustomPin(currCustomPin, ip).then((data) => {
                console.log(data);
              })
            }}>Update</Button>

            {/* Saving the custom pins added */}
            <Button variant="contained" onClick={() => {
              setCustomPin(currCustomPin);
              saveCustomPin();
            }}>Save</Button>

            {/* Clear the saved settings */}
            <Button variant="contained" onClick={() => {
              resetCustomPin();
            }}>Reset the saved pins</Button>

            {/* Load previous custom pin setting */}
            <Button variant="contained" onClick={() => {
              setCurrCustomPin(customPin);
            }}>Load previous value
              ({customPin})</Button>

          </Stack>

        </Box>

        <br />
        <br />

        {/* Settings */}
        <Box sx={{ flexGrow: 1 }}>
          <p>Settings</p>
          <Stack spacing={2} direction="row">
            {/* Input field to show the current ip */}
            <TextField
              id="filled-basic"
              variant="filled"
              color='primary'
              value={currIP}
              onChange={(e) => {
                setCurrIP(e.target.value);
              }}
            />

            {/* Update the ip */}
            <Button variant="contained" onClick={() => {
              setIP(currIP);
              updateIp();
              saveIP();
            }}>Update</Button>

          </Stack>
        </Box>

      </Container>
    </>
  )
}