const API = process.env.REACT_APP_API_URL;

const createUser = async (data) => {
    try {
        const response = await fetch(`${API}/user/create`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        // If the response 200, then alert the user that the account was created and redirect to the dashboard
        if (response.status === 201) {
            window.alert("Account created successfully");
        }
        const result = {
            status: response.status,
            data: await response.json(),
        };
        return result;
    }
    catch (err) {
        console.log(err);
        window.alert("Error creating user");
    }
}

const getUserEmail = async (email) => {
    try {
        const response = await fetch(`${API}/user/email/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(email),
        });
        return await response.json();
    }
    catch (err) {
        console.log(err);
    }
}

const getUserID = async (id) => {
    try {
        const response = await fetch(`${API}/user/id/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(id),
        });
        return await response.json();
    }
    catch (err) {
        console.log(err);
    }
}

const IDMapping = {
    "Oscillator": {
        "TEMP_OUT": "3,84",
        "FCLK@25KHz": "2,19,84",
        "FCLK@50KHz": "2,19,43,84",
        "FCLK@62.5KHz": "2,19,44,84",
        "FCLK@75KHz": "2,19,43,44,84",
        "FOC_OSC": "2,3,19,84",  
    },
    "Tests":  {
        "Vref_test": "9,12,84",
        "Iref_test": "12,84",
        "Cold_stop": "1,2,3,8,84",
        "Cold_over": "0,8,84",
    },
    "Channel1_top":  {
        "Clk_out": "3,10,84",
        "V_Qpump": "2,10,84",
        "Comp_out_FOC": "13,84",
        "FOC": "0,1,13,84",
        "FOC1": "0,13,84",
        "Mpp_0.5": "28",
        "Mpp_0.7": "28,30",
        "ZC_H": "0,17,84",
        "ZC_L": "1,17,84",
    },
     "Channel2_top":  {
        "Clk_out": "3,11,84",
        "V_Qpump": "2,11,84",
        "Comp_out_FOC": "14,84",
        "FOC": "0,1,14,84",
        "FOC2": "0,14,84",
        "Mpp_0.5": "31",
        "Mpp_0.7": "31,33",
        "ZC_H": "0,18,84",
        "ZC_L": "1,18,84",
    },
}
const updatePin = async (key, key2, ip) => {
    const pin = IDMapping[key][key2];
    try {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(`http://${ip}/user/get?pin=${pin};`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            window.alert("Test case " + key2 + " of the category " + key + " updated which added pins successfully " + pin);
        }
        return await pin;
    }
    catch (err) {
        console.log(err);
    }
}

// Custom input
const updateCustomPin = async (pin, ip) => {
    try {
        const response = await fetch(`http://${ip}/user/get?pin=${pin};`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            window.alert("Test case  updated which added pins successfully " + pin);
        }
        return await pin;
    }
    catch (err) {
        console.log(err);
    }
}


export { createUser, getUserEmail, getUserID, updatePin, updateCustomPin, IDMapping };
