const loginWithGoogle = async (token) => {
    try {
        const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const result  = await response.json();
        return result;
    }
    catch (err) {
        console.log(err);
        return err;
    }
}

export { loginWithGoogle };