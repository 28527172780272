import React, { createContext, useEffect, useState } from "react";
import { loginWithGoogle } from "../API/auth";
import Cookies from 'js-cookie'

export const PinContext = createContext();

const PinProvider = ({ children }) => {
    const [auth, setAuth] = useState(true);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [user, setUser] = useState({});
    const [role, setRole] = useState("user");

    const [ip, setIP] = useState("172.19.111.166:4000");

    // Custom pin
    const [customPin, setCustomPin] = useState("");

    const LoginGoogle = async (token) => {
        try {
            const res = await loginWithGoogle(token);
            if (res.status === 200) {
                setAuth(true);
                setEmail(res.user.email);
                setToken(token);
                setId(res.user._id);
                setName(res.user.name);
                setAuth(true);
                setUser(res.user);
                setRole(res.user.role);
                return (res.user);
            }
        }
        catch (err) {
            console.log(err);
            return err;
        }
    };

    const Logout = () => {
        setAuth(false);
        setEmail("");
        setToken("");
        setId("");
        setName("");
        setUser({});
    };

    const saveCustomPin = () => {
        Cookies.set('customPin', customPin);
        alert('Custom pin saved');
    }

    const resetCustomPin = () => {
        Cookies.remove('customPin');
        setCustomPin('');
        alert('Custom pin reset');
    }

    const saveIP = () => {
        Cookies.set('ip', ip);
        alert('IP saved');
    }

    useEffect(() => {
        setCustomPin(Cookies.get('customPin'));
        setIP(Cookies.get('ip'));
    }, []);

    const pinContext = {
        auth,
        setAuth,
        email,
        setEmail,
        token,
        setToken,
        id,
        setId,
        name,
        setName,
        LoginGoogle,
        Logout,
        user,
        setUser,
        role,
        setRole,
        customPin,
        setCustomPin,
        saveCustomPin,
        resetCustomPin,
        ip,
        setIP,
        saveIP
    };

    return (
        <PinContext.Provider value={pinContext}>
            {children}
        </PinContext.Provider>
    );
};

export default PinProvider;