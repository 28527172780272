import './App.css';
import AuthProvider from './Context/authContext';
import PinProvider from './Context/pinContext';
import Routing from './Routes';
import { GoogleOAuthProvider } from "@react-oauth/google"
import Navbar from './Component/Navbar';

function App() {
  const GoogleClientID = process.env.REACT_APP_CLIENT_ID;
  return (
    <>
      <GoogleOAuthProvider clientId={GoogleClientID}>
        <AuthProvider>
          <PinProvider>
            <Navbar />
            <Routing />
          </PinProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
